<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Questões</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1>
                  Gerenciamento de
                  <span id="txtDashboardNomePlataforma">Questões</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <section class="fd-app-content">
                <div class="text-center mt-3">
                  <a
                    class="btn btn-primary ml-2 mt-2"
                    href="#"
                    @click.prevent="showModal('modalCadastrarQuestao')"
                  >+ Cadastrar questão</a>
                  <modal
                    name="modalCadastrarQuestao"
                    :scrollable="true"
                    width="80%"
                    height="auto"
                    :shift-y="0.1"
                    :focus-trap="true"
                  >
                    <div class="row text-left p-4">
                      <div class="col-6 pl-4 pr-4">
                        <h4>Cadastrar questão</h4>
                      </div>
                      <div class="col-6 pl-4 pr-4 text-right">
                        <a
                          class="btn btn-secondary"
                          href="#"
                          @click.prevent="hideModal('modalCadastrarQuestao')"
                        >
                          <i
                            class="fa fa-times"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                      <div class="col-12 pt-4 pr-4 pb-0 pl-4">
                        <div class="row">
                          <div class="col-12">
                            <h6>Insira sua questão:</h6>
                          </div>
                          <div class="col-12">
                            <quill-editor
                              v-model="novaQuestao.pergunta"
                              :options="editorOption"
                            />
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-6">
                            <h6>Alternativa a)</h6>
                          </div>
                          <div class="col-6 text-right">
                            <div class="fd-app-meus-cursos mt-0">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div
                                  class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right"
                                >
                                  <label
                                    title="Correta?"
                                    class="switch"
                                  >
                                    <input type="checkbox">
                                    <span class="slider round" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <quill-editor
                              v-model="novaQuestao.alternativas[0].alternativa"
                              :options="editorOption"
                            />
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-6">
                            <h6>Alternativa b)</h6>
                          </div>
                          <div class="col-6 text-right">
                            <div class="fd-app-meus-cursos mt-0">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div
                                  class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right"
                                >
                                  <label
                                    title="Correta?"
                                    class="switch"
                                  >
                                    <input type="checkbox">
                                    <span class="slider round" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <quill-editor
                              v-model="novaQuestao.alternativas[1].alternativa"
                              :options="editorOption"
                            />
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-6">
                            <h6>Alternativa c)</h6>
                          </div>
                          <div class="col-6 text-right">
                            <div class="fd-app-meus-cursos mt-0">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div
                                  class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right"
                                >
                                  <label
                                    title="Correta?"
                                    class="switch"
                                  >
                                    <input type="checkbox">
                                    <span class="slider round" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <quill-editor
                              v-model="novaQuestao.alternativas[2].alternativa"
                              :options="editorOption"
                            />
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-6">
                            <h6>Alternativa d)</h6>
                          </div>
                          <div class="col-6 text-right">
                            <div class="fd-app-meus-cursos mt-0">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div
                                  class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right"
                                >
                                  <label
                                    title="Correta?"
                                    class="switch"
                                  >
                                    <input type="checkbox">
                                    <span class="slider round" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <quill-editor
                              v-model="novaQuestao.alternativas[3].alternativa"
                              :options="editorOption"
                            />
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-6">
                            <h6>Alternativa e)</h6>
                          </div>
                          <div class="col-6 text-right">
                            <div class="fd-app-meus-cursos mt-0">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div
                                  class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-right"
                                >
                                  <label
                                    title="Correta?"
                                    class="switch"
                                  >
                                    <input type="checkbox">
                                    <span class="slider round" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <quill-editor
                              v-model="novaQuestao.alternativas[4].alternativa"
                              :options="editorOption"
                            />
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-12">
                            <button
                              href="#"
                              type="button"
                              class="btn btn-primary"
                              @click.prevent="criaFastQuestao"
                            >
                              Criar questao
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </modal>
                </div>
                <div class="fd-plataforma-gestao" />
              </section>
              <!-- /fd-app-welcome -->
              <div
                v-if="questoesFiltro.length"
                class="row"
              >
                <div class="col-12 mb-2 table-responsive">
                  <h3>Base de questões ({{ questoesFiltro.length }})</h3>
                </div>
                <div class="col-12 mb-4 table-responsive">
                  <table class="table table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">
                          Id
                        </th>
                        <th scope="col">
                          Pergunta
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                        >
                          Alternativas
                        </th>
                        <th>Plataforma</th>
                        <th>Disciplina</th>
                        <th>Prova</th>
                        <th
                          class="text-center"
                          scope="col"
                        >
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tr
                      v-for="questao in pageOfQuestoes"
                      :key="questao.id_questao"
                    >
                      <td>{{ questao.id_questao }}</td>
                      <td>
                        <a
                          href="#"
                          @click.prevent="exibeModalEditarQuestao(questao.id_questao)"
                        >{{ removeHTMLpergunta(questao.pergunta) }}</a>
                      </td>
                      <td class="text-center">
                        *
                      </td>
                      <td class="text-center">
                        {{ questao.nome_plataforma }}
                      </td>
                      <td class="text-center">
                        {{ questao.disciplina }}
                      </td>
                      <td class="text-center">
                        {{ questao.nome_prova }}
                      </td>
                      <td class="text-center">
                        *
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-12 text-center mt-4">
                  <Pagination
                    :page-size="10"
                    :items="questoesFiltro"
                    @changePage="pageOfQuestoes = $event"
                  />
                </div>
              </div>
              <div
                v-else
                class="row"
              >
                <div class="col-12 mb-4 text-center">
                  <h4>Nenhuma questão encontrada</h4>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin/provas'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/provas')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      name="modalEditarQuestao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Editar questão</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarQuestao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div
            v-if="questaoEditar.id_questao"
            class="row"
          >
            <div class="col-12">
              <quill-editor
                v-model="questaoEditar.pergunta"
                :options="editorOption"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  name: "HomeInternoAdminPersonalizarEmails",
  components: {
    Pagination,
    quillEditor,
  },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Questoes
      questoesTotal: [],
      questoesFiltro: [],
      pageOfQuestoes: [],
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },
      // Nova questão
      novaQuestao: {
        pergunta: "",
        alternativas: [
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
          {
            alternativa: "",
            correta: false,
          },
        ],
      },
      // Questão editada
      questaoEditar: {},
      modalWidth: "80%",
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_prova) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getFastPlataformaQuestoes(this.$route.params.id_prova);
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastPlataformaQuestoes(id_prova) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_questao/lista?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_prova=" +
            id_prova,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        console.log(obj);
        if (obj.length > 0) {
          let grouped = this.groupBy(obj, "id_questao");

          let questoesSemAlternativa = obj.filter((q) => !q.id_alternativa);
          let questoesComAlternativa = obj.filter((q) => q.id_alternativa);

          console.log(grouped);

          //console.log(questoesComAlternativa);

          this.questoesTotal = obj;
          this.questoesFiltro = obj;

          this.$store.state.fastCarregando = false;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    groupBy(array, key) {
      const result = [];
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    criaFastQuestao() {
      if (this.novaQuestao.pergunta != "") {
        let fast_plataforma_questao = {
          pergunta: this.novaQuestao.pergunta,
          id_plataforma: this.$route.params.id_plataforma,
        };
        fetch(
          `${settings.endApiFastEad}api/fast_plataforma_questao/insere`,
          this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_questao))
        )
          .then((resp) => resp.json())
          .then((obj) => {
            if (obj.length > 0) {
              this.$notify({
                group: "foo",
                type: "success",
                duration: 5000,
                speed: 1000,
                title: "Status",
                text: "Questão cadastrada com sucesso",
              });

              this.questoesTotal = obj;
              this.questoesFiltro = obj;
              this.hideModal("modalCadastrarQuestao");

              let id_questao = obj[0].id_questao;
              if (id_questao) {
                // Insere alternativas
                this.novaQuestao.alternativas.forEach((a) => {
                  if (a.alternativa != "") {
                    let fast_plataforma_alternativa = {
                      id_questao: id_questao,
                      alternativa: a.alternativa,
                      correta: a.correta,
                      fast_plataforma_questao: {
                        id_plataforma: this.$route.params.id_plataforma,
                      },
                    };
                    fetch(
                      `${settings.endApiFastEad}api/fast_plataforma_alternativa/insere`,
                      this.fastAjaxOptions(
                        "POST",
                        JSON.stringify(fast_plataforma_alternativa)
                      )
                    )
                      .then((resp) => resp.json())
                      .then((obj) => {
                        if (obj.length > 0) {
                          this.$notify({
                            group: "foo",
                            type: "success",
                            duration: 5000,
                            speed: 1000,
                            title: "Status",
                            text: "Alternativa cadastrada com sucesso",
                          });
                        }
                      });
                  }
                });
              }
              t;
            }
          })
          .catch((e) => console.log(e));
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Erro",
          text: "A questão não pode ficar em branco.",
        });
      }
    },
    removeHTMLpergunta(str) {
      let strFormated = str.replace(/<[^>]+>/g, "");
      if (strFormated.length > 10)
        strFormated = strFormated.substring(0, 80) + "...";
      return strFormated;
    },
    exibeModalEditarQuestao(id_questao) {
      let q = this.questoesTotal.filter((q) => q.id_questao == id_questao);
      this.questaoEditar = q[0];
      this.showModal("modalEditarQuestao");
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Tabs */
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 1em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
</style>
